import { upperFirst, lowerCase } from 'lodash';
import { gtmBasicEvent } from '@klv/shared/utils/google-tag-manager';

export const gtmClick = ({ childItem, menuItem }: { childItem: string; menuItem?: string; }) => {
  gtmBasicEvent({
    action: menuItem,
    category: 'header_nav',
    label: childItem,
  });
};

export const storeLastViewedAcademyContent = (path: string) => {
  const getCourseName = ( url: string) => {
    const match = url.match(/\/(?:courses|collections)\/([^\/]+)/);
  
    return match ? upperFirst(lowerCase(match[1])) : null; 
  };

  const lastViewedAcademyContent = {
    path: path,
    title: getCourseName(path),
  };

  sessionStorage.setItem('lastViewedAcademyContent', JSON.stringify(lastViewedAcademyContent));
};

export const loginActions = ({ childItem: label }: { childItem: string; }) => {
  const url = new URL(window.location.href);
  if (url.hostname.startsWith('academy') && url.hostname.endsWith('.klaviyo.com')) {
    if (location?.pathname.includes('/courses') || location?.pathname.includes('/collections')) {
      storeLastViewedAcademyContent(location.pathname);
    }
  }
  gtmClick({ childItem: label });
};
