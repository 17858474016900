module.exports = [{
      plugin: require('../../../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://8b51d99fe9580dc758c6cd863926b658@o19233.ingest.us.sentry.io/4507777483603968","environment":"production","sampleRate":1,"tracesSampleRate":0.1},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"disableVendorPrefixes":true,"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"/opt/build/repo/packages/gatsby-theme-core/dist/assets/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0fe6c9777057a06e76a2ec177be948b9"},
    },{
      plugin: require('../../../packages/gatsby-theme-core/gatsby-browser.tsx'),
      options: {"plugins":[],"graphqlEndpoint":"https://k-ops.services.klaviyo.com/academy","imageDirectory":"/opt/build/repo/sites/academy/src/assets/images","includeRssFeed":false,"klaviyoCompanyId":"9BX3wh","langCode":"","navigationGraphqlEndpoint":"https://klaviyocms.wpengine.com/graphql","navigationLocales":["en-US"],"redirects":[{"fromPath":"/","toPath":"/en-us"},{"fromPath":"/en-us/courses/getting-started-with-klaviyo/*","toPath":"https://www.klaviyo.com/customer-resources/getting-started","statusCode":301},{"fromPath":"/getting-started-with-klaviyo/*","toPath":"https://www.klaviyo.com/customer-resources/getting-started","statusCode":301}],"siteUrl":"https://academy.klaviyo.com"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
