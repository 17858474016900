exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-collection-index-tsx": () => import("./../../../src/templates/collection/index.tsx" /* webpackChunkName: "component---src-templates-collection-index-tsx" */),
  "component---src-templates-course-index-tsx": () => import("./../../../src/templates/course/index.tsx" /* webpackChunkName: "component---src-templates-course-index-tsx" */),
  "component---src-templates-home-index-tsx": () => import("./../../../src/templates/home/index.tsx" /* webpackChunkName: "component---src-templates-home-index-tsx" */),
  "component---src-templates-lesson-index-tsx": () => import("./../../../src/templates/lesson/index.tsx" /* webpackChunkName: "component---src-templates-lesson-index-tsx" */),
  "component---src-templates-profile-index-tsx": () => import("./../../../src/templates/profile/index.tsx" /* webpackChunkName: "component---src-templates-profile-index-tsx" */),
  "component---src-templates-search-index-tsx": () => import("./../../../src/templates/search/index.tsx" /* webpackChunkName: "component---src-templates-search-index-tsx" */)
}

